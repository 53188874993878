import * as React from "react";
import { Layout, PageTitle, Vibe } from "../../components";
import Seo from "../../components/Seo";

const VibePage = () => {
  return (
    <Layout>
      <Seo title="VIBE - emo TOKYOの楽しみ方" />
      <PageTitle title="VIBE" subTitle="emo TOKYOの楽しみ方" />
      <Vibe />
    </Layout>
  );
};

export default VibePage;
